<template>
  <div class="payment">
    <h1 class="title">{{ $t('createPayment.title') }}</h1>
    <div class="payment-field">
      <label class="area1 label" for="">{{
        $t('createPayment.company')
      }}</label>
      <label class="area2 label" for=""
        >{{ $t('createPayment.amount') }} ({{ currency }})</label
      >
      <label class="area3 label" for="">{{
        $t('createPayment.numberPayment')
      }}</label>
      <el-select
        class="adminSelect w-full area4"
        v-model="company"
        filterable
        collapse-tags
      >
        <el-option
          class="text-ellipsis"
          style="max-width: 265px"
          v-for="item in companies"
          :key="item.id"
          :value="item.id"
          :label="item.name || '-'"
        />
      </el-select>
      <input
        required
        class="rounded focus_outline-none pl-4 pr-8 w-full text-sm font-medium border border-borderColor area5"
        type="Number"
        style="height: 35px"
        v-model="amount"
      />

      <input
        v-model="custom_order_id"
        class="rounded focus_outline-none pl-4 pr-8 w-full text-sm font-medium border border-borderColor area6"
        type="text"
        style="height: 35px"
      />
      <skif-button
        :disabled="!isHaveDisabled"
        class="w-full area7"
        @click="createBill"
        >{{ $t('createPayment.createPayment') }}</skif-button
      >
      <p class="description w-full area8">
        <InfoIcon />
        <span class="description__text">{{
          $t('createPayment.upBalance')
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoIcon from './assets/icons/InfoIcon.vue'

export default {
  name: 'CreatePayment',
  components: {
    InfoIcon
  },
  props: {
    companies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      company: null,
      amount: null,
      custom_order_id: null
    }
  },
  computed: {
    ...mapGetters({
      me: 'login/me'
    }),
    isHaveDisabled() {
      return this.company !== null && this.amount !== null
    },
    currency() {
      return this.me.active_company.billing.currency.code
    }
  },
  methods: {
    ...mapActions({
      sendBill: 'payments/billTransaction'
    }),
    createBill() {
      if (!this.checkValidation()) return
      this.sendBill({
        company_id: this.company,
        amount: Number(this.amount),
        custom_order_id: this.custom_order_id
      })
        .then((response) => {
          this.$emit('close-create-payment')
          this.$notify.success({
            duration: 5000,
            title: this.$t('createPayment.error.createPayment'),
            message: response.statusText
          })
        })
        .catch((error) => {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error.response.data.message
          })
        })
    },
    checkValidation() {
      if (!this.company) {
        this.$notify.error({
          duration: 5000,
          title: this.$t('createPayment.error.error'),
          message: this.$t('createPayment.error.enterCompany')
        })
        return false
      }
      if (!this.amount) {
        this.$notify.error({
          duration: 5000,
          title: this.$t('createPayment.error.error'),
          message: this.$t('createPayment.error.enterAmount')
        })
        return false
      }
      return true
    }
  }
}
</script>

<style lang="stylus">
.payment
  position absolute
  top 302px
  background white
  z-index 11
  padding-top 16px
  box-shadow 0px 6px 12px rgba(0, 0, 0, 0.08)
  border 1px solid #e2e8f0
  .title
    margin-bottom 16px
    margin-left 25px
    font-weight 700
    font-size 18px
    color #446C9D

  .payment-field
    display grid
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: 16px 1fr 1fr
    gap: 16px 16px
    grid-template-areas \
      "area1 area2 area3" \
      "area4 area5 area6" \
      "area7 area8 area8"
    padding 0 25px 25px 25px

    .label
      font-weight 600
      font-size 14px
      color #81ABE1

    .description
      display flex
      align-items center
      &__text
        margin-left 8px
        font-weight 400
        font-size 14px
        color #B9C5CE
        line-height 100%

  .area1
    grid-area area1
  .area2
    grid-area area2
  .area3
    grid-area area3
  .area4
    grid-area area4
  .area5
    grid-area area5
  .area6
    grid-area area6
  .area7
    grid-area area7
  .area8
    grid-area area8
</style>
